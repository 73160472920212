.IrisMenuButton {
    margin-left: 20px;
    margin-top: 20px;
    background-color: rgb(234, 234, 234);
}

@media only screen and (max-width: 500px) {
    .IrisMenuButton {
        margin-left: 5px;
        margin-top: 20px;
    }
}