.NavGrid {
    height: 47px;
    background-color: #184573;
    opacity: 0.92;
    text-align: center;
}

@media only screen and (max-width: 382px) {
    .NavGrid {
        height: 72px;
    }
}