.headingMed {
    display: none;
}

.IrisBackground {
    margin-top: -3.5vw;
    margin-bottom: 20px;
}

@media only screen and (max-width: 675px) {
    .headingMed {
        display: block;
    }
    .headingBig {
        display: none;
    }
}

@media only screen and (min-width: 1400px) {
    .IrisBackground {
        margin-top: -3.5vw;
    }
}

@media only screen and (min-width: 1700px) {
    .IrisBackground {
        margin-top: -2.5vw;
    }
}

@media only screen and (min-width: 2000px) {
    .IrisBackground {
        margin-top: -1.5vw;
    }
}

@media only screen and (min-width: 2500px) {
    .IrisBackground {
        margin-top: 0;
    }
}